const NFLPOSITIONS = [
  'ALL',
  'QB',
  'RB',
  'WR',
  'TE',
  'OG',
  'OT',
  'OC',
  'EDGE',
  'DL',
  'LB',
  'S',
  'CB',
];

const FANTASYPOSITIONS = [];

export { NFLPOSITIONS, FANTASYPOSITIONS };
