/* eslint-disable quotes */
const players = {
  'Joe Burrow': {
    name: 'Joe Burrow',
    sch: 'LSU',
    pos: 'QB',
    height: `6'4"`,
    weight: '216',
  },
  'Chase Young': {
    name: 'Chase Young',
    sch: 'Ohio State',
    pos: 'EDGE',
    height: `6'5"`,
    weight: '265',
  },
  'Derrick Brown': {
    name: 'Derrick Brown',
    sch: 'Auburn',
    pos: 'DL',
    height: `6'5"`,
    weight: '318',
  },
  'Andrew Thomas': {
    name: 'Andrew Thomas',
    sch: 'Georgia',
    pos: 'OT',
    height: `6'5"`,
    weight: '320',
  },
  'CeeDee Lamb': {
    name: 'CeeDee Lamb',
    sch: 'Oklahoma',
    pos: 'WR',
    height: `6'2"`,
    weight: '189',
  },
  'Jeffrey Okudah': {
    name: 'Jeffrey Okudah',
    sch: 'Ohio State',
    pos: 'CB',
    height: `6'1"`,
    weight: '200',
  },
  'Isaiah Simmons': {
    name: 'Isaiah Simmons',
    sch: 'Clemson',
    pos: 'LB',
    height: `6'4"`,
    weight: '225',
  },
  'Justin Herbert': {
    name: 'Justin Herbert',
    sch: 'Oregon',
    pos: 'QB',
    height: `6'6"`,
    weight: '225',
  },
  'A.J. Epenesa': {
    name: 'A.J. Epenesa',
    sch: 'Iowa',
    pos: 'DL',
    height: `6'6"`,
    weight: '280',
  },
  'Jedrick Wills': {
    name: 'Jedrick Wills',
    sch: 'Alabama',
    pos: 'OT',
    height: `6'5"`,
    weight: '316',
  },
  'Tristan Wirfs': {
    name: 'Tristan Wirfs',
    sch: 'Iowa',
    pos: 'OT',
    height: `6'5"`,
    weight: '320',
  },
  'Tua Tagovailoa': {
    name: 'Tua Tagovailoa',
    sch: 'Alabama',
    pos: 'QB',
    height: `6'1"`,
    weight: '218',
  },
  'Jerry Jeudy': {
    name: 'Jerry Jeudy',
    sch: 'Alabama',
    pos: 'WR',
    height: `6'1"`,
    weight: '192',
  },
  'Kristian Fulton': {
    name: 'Kristian Fulton',
    sch: 'LSU',
    pos: 'CB',
    height: `6'0"`,
    weight: '192',
  },
  'Javon Kinlaw': {
    name: 'Javon Kinlaw',
    sch: 'South Carolina',
    pos: 'DL',
    height: `6'6"`,
    weight: '302',
  },
  'Grant Delpit': {
    name: 'Grant Delpit',
    sch: 'LSU',
    pos: 'S',
    height: `6'3"`,
    weight: '203',
  },
  'Yetur Gross-Matos': {
    name: 'Yetur Gross-Matos',
    sch: 'Penn State',
    pos: 'EDGE',
    height: `6'4"`,
    weight: '262',
  },
  'Austin Jackson': {
    name: 'Austin Jackson',
    sch: 'USC',
    pos: 'OT',
    height: `6'6"`,
    weight: '310',
  },
  "K'Lavon Chaisson": {
    name: "K'Lavon Chaisson",
    sch: 'LSU',
    pos: 'LB',
    height: `6'4"`,
    weight: '238',
  },
  'Tee Higgins': {
    name: 'Tee Higgins',
    sch: 'Clemson',
    pos: 'WR',
    height: `6'4"`,
    weight: '200',
  },
  'Solomon KinDLey': {
    name: 'Solomon KinDLey',
    sch: 'Georgia',
    pos: 'OG',
    height: `6'4"`,
    weight: '335',
  },
  'Trevon Diggs': {
    name: 'Trevon Diggs',
    sch: 'Alabama',
    pos: 'CB',
    height: `6'2"`,
    weight: '200',
  },
  'Clyde Edwards-Helaire': {
    name: 'Clyde Edwards-Helaire',
    sch: 'LSU',
    pos: 'RB',
    height: `5'9"`,
    weight: '212',
  },
  'Curtis Weaver': {
    name: 'Curtis Weaver',
    sch: 'Boise State',
    pos: 'EDGE',
    height: `6'3"`,
    weight: '266',
  },
  'Tyler Biadasz': {
    name: 'Tyler Biadasz',
    sch: 'Wisconsin',
    pos: 'OC',
    height: `6'3"`,
    weight: '315',
  },
  'Xavier McKinney': {
    name: 'Xavier McKinney',
    sch: 'Alabama',
    pos: 'S',
    height: `6'1"`,
    weight: '196',
  },
  'Jonathan Taylor': {
    name: 'Jonathan Taylor',
    sch: 'Wisconsin',
    pos: 'RB',
    height: `6'1"`,
    weight: '215',
  },
  'Trey Adams': {
    name: 'Trey Adams',
    sch: 'Washington',
    pos: 'OT',
    height: `6'7"`,
    weight: '315',
  },
  'Henry Ruggs III': {
    name: 'Henry Ruggs III',
    sch: 'Alabama',
    pos: 'WR',
    height: `6'0"`,
    weight: '183',
  },
  'Cameron Dantzler': {
    name: 'Cameron Dantzler',
    sch: 'Mississippi State',
    pos: 'CB',
    height: `6'2"`,
    weight: '185',
  },
  "D'Andre Swift": {
    name: "D'Andre Swift",
    sch: 'Georgia',
    pos: 'RB',
    height: `5'9"`,
    weight: '215',
  },
  'Laviska Shenault': {
    name: 'Laviska Shenault',
    sch: 'Colorado',
    pos: 'WR',
    height: `6'2"`,
    weight: '220',
  },
  'C.J. Henderson': {
    name: 'C.J. Henderson',
    sch: 'Florida',
    pos: 'CB',
    height: `6'1"`,
    weight: '186',
  },
  'Mekhi Becton': {
    name: 'Mekhi Becton',
    sch: 'Louisville',
    pos: 'OT',
    height: `6'7"`,
    weight: '355',
  },
  'Brycen Hopkins': {
    name: 'Brycen Hopkins',
    sch: 'Purdue',
    pos: 'TE',
    height: `6'5"`,
    weight: '245',
  },
  'Jeff Gladney': {
    name: 'Jeff Gladney',
    sch: 'TCU',
    pos: 'CB',
    height: `6'0"`,
    weight: '183',
  },
  'J.K. Dobbins': {
    name: 'J.K. Dobbins',
    sch: 'Ohio State',
    pos: 'RB',
    height: `6'0"`,
    weight: '214',
  },
  'Josh Jones': {
    name: 'Josh Jones',
    sch: 'Houston',
    pos: 'OT',
    height: `6'7"`,
    weight: '310',
  },
  'Justin Jefferson': {
    name: 'Justin Jefferson',
    sch: 'LSU',
    pos: 'WR',
    height: `6'2"`,
    weight: '185',
  },
  'Lloyd Cushenberry': {
    name: 'Lloyd Cushenberry',
    sch: 'LSU',
    pos: 'OC',
    height: `6'3"`,
    weight: '312',
  },
  'Neville Gallimore': {
    name: 'Neville Gallimore',
    sch: 'Oklahoma',
    pos: 'DL',
    height: `6'2"`,
    weight: '330',
  },
  'Bryce Hall': {
    name: 'Bryce Hall',
    sch: 'Virginia',
    pos: 'CB',
    height: `6'1"`,
    weight: '200',
  },
  'Kenneth Murray': {
    name: 'Kenneth Murray',
    sch: 'Oklahoma',
    pos: 'LB',
    height: `6'2"`,
    weight: '238',
  },
  'Adam Trautman': {
    name: 'Adam Trautman',
    sch: 'Dayton',
    pos: 'TE',
    height: `6'5"`,
    weight: '250',
  },
  'K.J. Hamler': {
    name: 'K.J. Hamler',
    sch: 'Penn State',
    pos: 'WR',
    height: `5'9"`,
    weight: '174',
  },
  'Prince Tega Wanogho': {
    name: 'Prince Tega Wanogho',
    sch: 'Auburn',
    pos: 'OT',
    height: `6'7"`,
    weight: '310',
  },
  'James Lynch': {
    name: 'James Lynch',
    sch: 'Baylor',
    pos: 'DL',
    height: `6'4"`,
    weight: '295',
  },
  'Zack Baun': {
    name: 'Zack Baun',
    sch: 'Wisconsin',
    pos: 'LB',
    height: `6'3"`,
    weight: '235',
  },
  'John Simpson': {
    name: 'John Simpson',
    sch: 'Clemson',
    pos: 'OG',
    height: `6'4"`,
    weight: '330',
  },
  'Jake Fromm': {
    name: 'Jake Fromm',
    sch: 'Georgia',
    pos: 'QB',
    height: `6'2"`,
    weight: '220',
  },
  'Jalen Reagor': {
    name: 'Jalen Reagor',
    sch: 'TCU',
    pos: 'WR',
    height: `6'1"`,
    weight: '195',
  },
  'Yasir Durant': {
    name: 'Yasir Durant',
    sch: 'Missouri',
    pos: 'OT',
    height: `6'7"`,
    weight: '330',
  },
  'Shane Lemieux': {
    name: 'Shane Lemieux',
    sch: 'Oregon',
    pos: 'OG',
    height: `6'4"`,
    weight: '317',
  },
  'Terrell Lewis': {
    name: 'Terrell Lewis',
    sch: 'Alabama',
    pos: 'EDGE',
    height: `6'5"`,
    weight: '255',
  },
  'Jordan Love': {
    name: 'Jordan Love',
    sch: 'Utah State',
    pos: 'QB',
    height: `6'4"`,
    weight: '225',
  },
  'A.J. Terrell': {
    name: 'A.J. Terrell',
    sch: 'Clemson',
    pos: 'CB',
    height: `6'2"`,
    weight: '190',
  },
  'Brandon Aiyuk': {
    name: 'Brandon Aiyuk',
    sch: 'Arizona State',
    pos: 'WR',
    height: `6'0"`,
    weight: '208',
  },
  'Patrick Queen': {
    name: 'Patrick Queen',
    sch: 'LSU',
    pos: 'LB',
    height: `6'2"`,
    weight: '232',
  },
  'Michael Pittman Jr.': {
    name: 'Michael Pittman Jr.',
    sch: 'USC',
    pos: 'WR',
    height: `6'4"`,
    weight: '223',
  },
  'Tyler Johnson': {
    name: 'Tyler Johnson',
    sch: 'Minnesota',
    pos: 'WR',
    height: `6'2"`,
    weight: '200',
  },
  'Raekwon Davis': {
    name: 'Raekwon Davis',
    sch: 'Alabama',
    pos: 'DL',
    height: `6'7"`,
    weight: '306',
  },
  'Ben Bredeson': {
    name: 'Ben Bredeson',
    sch: 'Michigan',
    pos: 'OG',
    height: `6'5"`,
    weight: '320',
  },
  'Justin Madubuike': {
    name: 'Justin Madubuike',
    sch: 'Texas A&M',
    pos: 'DL',
    height: `6'3"`,
    weight: '304',
  },
  'Malik Harrison': {
    name: 'Malik Harrison',
    sch: 'Ohio State',
    pos: 'LB',
    height: `6'3"`,
    weight: '245',
  },
  'Antoine Winfield Jr.': {
    name: 'Antoine Winfield Jr.',
    sch: 'Minnesota',
    pos: 'S',
    height: `6'0"`,
    weight: '207',
  },
  'Marlon Davidson': {
    name: 'Marlon Davidson',
    sch: 'Auburn',
    pos: 'DL',
    height: `6'3"`,
    weight: '278',
  },
  'Jacob Eason': {
    name: 'Jacob Eason',
    sch: 'Washington',
    pos: 'QB',
    height: `6'6"`,
    weight: '228',
  },
  'Jacob Phillips': {
    name: 'Jacob Phillips',
    sch: 'LSU',
    pos: 'LB',
    height: `6'4"`,
    weight: '229',
  },
  'Cesar Ruiz': {
    name: 'Cesar Ruiz',
    sch: 'Michigan',
    pos: 'OC',
    height: `6'4"`,
    weight: '319',
  },
  'Dele Harding': {
    name: 'Dele Harding',
    sch: 'Illinois',
    pos: 'LB',
    height: `6'1"`,
    weight: '230',
  },
  'Jaylon Johnson': {
    name: 'Jaylon Johnson',
    sch: 'Utah',
    pos: 'CB',
    height: `6'0"`,
    weight: '190',
  },
  'Jalen Hurts': {
    name: 'Jalen Hurts',
    sch: 'Oklahoma',
    pos: 'QB',
    height: `6'2"`,
    weight: '210',
  },
  'Troy Dye': {
    name: 'Troy Dye',
    sch: 'Oregon',
    pos: 'LB',
    height: `6'4"`,
    weight: '224',
  },
  'Isaiah Wilson': {
    name: 'Isaiah Wilson',
    sch: 'Georgia',
    pos: 'OT',
    height: `6'7"`,
    weight: '340',
  },
  'Albert Okwuegbunam': {
    name: 'Albert Okwuegbunam',
    sch: 'Missouri',
    pos: 'TE',
    height: `6'5"`,
    weight: '255',
  },
  'Julian Okwara': {
    name: 'Julian Okwara',
    sch: 'Notre Dame',
    pos: 'EDGE',
    height: `6'5"`,
    weight: '241',
  },
  'Anfernee Jennings': {
    name: 'Anfernee Jennings',
    sch: 'Alabama',
    pos: 'LB',
    height: `6'3"`,
    weight: '259',
  },
  'Hakeem Adeniji': {
    name: 'Hakeem Adeniji',
    sch: 'Kansas',
    pos: 'OG',
    height: `6'5"`,
    weight: '310',
  },
  'Hunter Bryant': {
    name: 'Hunter Bryant',
    sch: 'Washington',
    pos: 'TE',
    height: `6'2"`,
    weight: '241',
  },
  'A.J. Green': {
    name: 'A.J. Green',
    sch: 'Oklahoma State',
    pos: 'CB',
    height: `6'1"`,
    weight: '194',
  },
  'K.J. Hill': {
    name: 'K.J. Hill',
    sch: 'Ohio State',
    pos: 'WR',
    height: `6'0"`,
    weight: '195',
  },
  'Cam Akers': {
    name: 'Cam Akers',
    sch: 'Florida State',
    pos: 'RB',
    height: `6'1"`,
    weight: '210',
  },
  "K'Von Wallace": {
    name: "K'Von Wallace",
    sch: 'Clemson',
    pos: 'S',
    height: `6'1"`,
    weight: '210',
  },
  'Jordyn Brooks': {
    name: 'Jordyn Brooks',
    sch: 'Texas Tech',
    pos: 'LB',
    height: `6'1"`,
    weight: '245',
  },
  'BraDLee Anae': {
    name: 'BraDLee Anae',
    sch: 'Utah',
    pos: 'EDGE',
    height: `6'3"`,
    weight: '265',
  },
  'Shyheim Carter': {
    name: 'Shyheim Carter',
    sch: 'Alabama',
    pos: 'CB',
    height: `6'0"`,
    weight: '195',
  },
  'Donovan Peoples-Jones': {
    name: 'Donovan Peoples-Jones',
    sch: 'Michigan',
    pos: 'WR',
    height: `6'2"`,
    weight: '208',
  },
  'Brandon Jones': {
    name: 'Brandon Jones',
    sch: 'Texas',
    pos: 'S',
    height: `6'0"`,
    weight: '210',
  },
  'Colby Parkinson': {
    name: 'Colby Parkinson',
    sch: 'Stanford',
    pos: 'TE',
    height: `6'7"`,
    weight: '240',
  },
  'Larrell Murchison': {
    name: 'Larrell Murchison',
    sch: 'NC State',
    pos: 'DL',
    height: `6'3"`,
    weight: '291',
  },
  'Calvin Throckmorton': {
    name: 'Calvin Throckmorton',
    sch: 'Oregon',
    pos: 'OT',
    height: `6'5"`,
    weight: '318',
  },
  'Rashard Lawrence': {
    name: 'Rashard Lawrence',
    sch: 'LSU',
    pos: 'DL',
    height: `6'3"`,
    weight: '317',
  },
  'Denzel Mims': {
    name: 'Denzel Mims',
    sch: 'Baylor',
    pos: 'WR',
    height: `6'3"`,
    weight: '197',
  },
  'Logan Stenberg': {
    name: 'Logan Stenberg',
    sch: 'Kentucky',
    pos: 'OG',
    height: `6'6"`,
    weight: '326',
  },
  'Ashtyn Davis': {
    name: 'Ashtyn Davis',
    sch: 'California',
    pos: 'S',
    height: `6'1"`,
    weight: '201',
  },
  'Devin Duvernay': {
    name: 'Devin Duvernay',
    sch: 'Texas',
    pos: 'WR',
    height: `6'1"`,
    weight: '186',
  },
  'Josh Uche': {
    name: 'Josh Uche',
    sch: 'Michigan',
    pos: 'LB',
    height: `6'3"`,
    weight: '238',
  },
  'Darryl Williams': {
    name: 'Darryl Williams',
    sch: 'Mississippi State',
    pos: 'OC',
    height: `6'2"`,
    weight: '310',
  },
  'Ross Blacklock': {
    name: 'Ross Blacklock',
    sch: 'TCU',
    pos: 'DL',
    height: `6'4"`,
    weight: '305',
  },
  'Leki Fotu': {
    name: 'Leki Fotu',
    sch: 'Utah',
    pos: 'DL',
    height: `6'5"`,
    weight: '330',
  },
  'Cole Kmet': {
    name: 'Cole Kmet',
    sch: 'Notre Dame',
    pos: 'TE',
    height: `6'6"`,
    weight: '255',
  },
  'James Proche': {
    name: 'James Proche',
    sch: 'SMU',
    pos: 'WR',
    height: `6'1"`,
    weight: '190',
  },
  'Collin Johnson': {
    name: 'Collin Johnson',
    sch: 'Texas',
    pos: 'WR',
    height: `6'6"`,
    weight: '220',
  },
  'Akeem Davis-Gaither': {
    name: 'Akeem Davis-Gaither',
    sch: 'Appalachian State',
    pos: 'LB',
    height: `6'2"`,
    weight: '210',
  },
  'Netane Muti': {
    name: 'Netane Muti',
    sch: 'Fresno State',
    pos: 'OG',
    height: `6'3"`,
    weight: '310',
  },
  'Lamar Jackson': {
    name: 'Lamar Jackson',
    sch: 'Nebraska',
    pos: 'CB',
    height: `6'2"`,
    weight: '215',
  },
  'Trevis Gipson': {
    name: 'Trevis Gipson',
    sch: 'Tulsa',
    pos: 'DL',
    height: `6'4"`,
    weight: '268',
  },
  'Zack Moss': {
    name: 'Zack Moss',
    sch: 'Utah',
    pos: 'RB',
    height: `6'0"`,
    weight: '210',
  },
  'Kenny Willekes': {
    name: 'Kenny Willekes',
    sch: 'Michigan State',
    pos: 'EDGE',
    height: `6'4"`,
    weight: '260',
  },
  'Jonathan Greenard': {
    name: 'Jonathan Greenard',
    sch: 'Florida',
    pos: 'EDGE',
    height: `6'3"`,
    weight: '263',
  },
  'Anthony Gordon': {
    name: 'Anthony Gordon',
    sch: 'Washington State',
    pos: 'QB',
    height: `6'3"`,
    weight: '210',
  },
  'Antonio Gandy-Golden': {
    name: 'Antonio Gandy-Golden',
    sch: 'Liberty',
    pos: 'WR',
    height: `6'4"`,
    weight: '216',
  },
  'Davon Hamilton': {
    name: 'Davon Hamilton',
    sch: 'Ohio State',
    pos: 'DL',
    height: `6'4"`,
    weight: '310',
  },
  'Joe Gaziano': {
    name: 'Joe Gaziano',
    sch: 'Northwestern',
    pos: 'DL',
    height: `6'4"`,
    weight: '275',
  },
  "Ke'Shawn Vaughn": {
    name: "Ke'Shawn Vaughn",
    sch: 'Vanderbilt',
    pos: 'RB',
    height: `6'0"`,
    weight: '215',
  },
  'Jordan Elliott': {
    name: 'Jordan Elliott',
    sch: 'Missouri',
    pos: 'DL',
    height: `6'4"`,
    weight: '315',
  },
  'Jake Hanson': {
    name: 'Jake Hanson',
    sch: 'Oregon',
    pos: 'OC',
    height: `6'5"`,
    weight: '297',
  },
  'Darrell Taylor': {
    name: 'Darrell Taylor',
    sch: 'Tennessee',
    pos: 'EDGE',
    height: `6'4"`,
    weight: '247',
  },
  'Troy Pride Jr.': {
    name: 'Troy Pride Jr.',
    sch: 'Notre Dame',
    pos: 'CB',
    height: `6'1"`,
    weight: '194',
  },
  'Jordan Mack': {
    name: 'Jordan Mack',
    sch: 'Virginia',
    pos: 'LB',
    height: `6'2"`,
    weight: '230',
  },
  'Eno Benjamin': {
    name: 'Eno Benjamin',
    sch: 'Arizona State',
    pos: 'RB',
    height: `6'0"`,
    weight: '201',
  },
  'Bryan Edwards': {
    name: 'Bryan Edwards',
    sch: 'South Carolina',
    pos: 'WR',
    height: `6'3"`,
    weight: '215',
  },
  'Thaddeus Moss': {
    name: 'Thaddeus Moss',
    sch: 'LSU',
    pos: 'TE',
    height: `6'3"`,
    weight: '249',
  },
  'Khalid Kareem': {
    name: 'Khalid Kareem',
    sch: 'Notre Dame',
    pos: 'EDGE',
    height: `6'4"`,
    weight: '265',
  },
  'Lucas Niang': {
    name: 'Lucas Niang',
    sch: 'TCU',
    pos: 'OT',
    height: `6'7"`,
    weight: '328',
  },
  'Jauan Jennings': {
    name: 'Jauan Jennings',
    sch: 'Tennessee',
    pos: 'WR',
    height: `6'3"`,
    weight: '204',
  },
  'Jonah Jackson': {
    name: 'Jonah Jackson',
    sch: 'Ohio State',
    pos: 'OG',
    height: `6'3"`,
    weight: '309',
  },
  'Noah Igbinoghene': {
    name: 'Noah Igbinoghene',
    sch: 'Auburn',
    pos: 'CB',
    height: `6'1"`,
    weight: '200',
  },
  'McTelvin Agim': {
    name: 'McTelvin Agim',
    sch: 'Arkansas',
    pos: 'DL',
    height: `6'3"`,
    weight: '279',
  },
  'Robert Hunt': {
    name: 'Robert Hunt',
    sch: 'Louisiana-Lafayette',
    pos: 'OT',
    height: `6'5"`,
    weight: '310',
  },
  'Antoine Brooks Jr.': {
    name: 'Antoine Brooks Jr.',
    sch: 'Maryland',
    pos: 'S',
    height: `6'1"`,
    weight: '210',
  },
  'Raequan Williams': {
    name: 'Raequan Williams',
    sch: 'Michigan State',
    pos: 'DL',
    height: `6'4"`,
    weight: '298',
  },
  'Damien Lewis': {
    name: 'Damien Lewis',
    sch: 'LSU',
    pos: 'OG',
    height: `6'2"`,
    weight: '335',
  },
  'Nick Harris': {
    name: 'Nick Harris',
    sch: 'Washington',
    pos: 'OC',
    height: `6'1"`,
    weight: '302',
  },
  'DeeJay Dallas': {
    name: 'DeeJay Dallas',
    sch: 'Miami (FL)',
    pos: 'RB',
    height: `6'0"`,
    weight: '214',
  },
  'Chris Orr': {
    name: 'Chris Orr',
    sch: 'Wisconsin',
    pos: 'LB',
    height: `6'0"`,
    weight: '224',
  },
  'Quartney Davis': {
    name: 'Quartney Davis',
    sch: 'Texas A&M',
    pos: 'WR',
    height: `6'2"`,
    weight: '200',
  },
  'Kyle Dugger': {
    name: 'Kyle Dugger',
    sch: 'Lenoir-Rhyne',
    pos: 'S',
    height: `6'2"`,
    weight: '205',
  },
  'Trevon Hill': {
    name: 'Trevon Hill',
    sch: 'Miami (FL)',
    pos: 'EDGE',
    height: `6'3"`,
    weight: '245',
  },
  'Evan Weaver': {
    name: 'Evan Weaver',
    sch: 'California',
    pos: 'LB',
    height: `6'3"`,
    weight: '245',
  },
  'Van Jefferson': {
    name: 'Van Jefferson',
    sch: 'Florida',
    pos: 'WR',
    height: `6'2"`,
    weight: '197',
  },
  'Ezra Cleveland': {
    name: 'Ezra Cleveland',
    sch: 'Boise State',
    pos: 'OT',
    height: `6'5"`,
    weight: '310',
  },
  'Alex Highsmith': {
    name: 'Alex Highsmith',
    sch: 'Charlotte',
    pos: 'EDGE',
    height: `6'3"`,
    weight: '244',
  },
  'Shaquille Quarterman': {
    name: 'Shaquille Quarterman',
    sch: 'Miami (FL)',
    pos: 'LB',
    height: `6'1"`,
    weight: '240',
  },
  'Calvin Taylor Jr.': {
    name: 'Calvin Taylor Jr.',
    sch: 'Kentucky',
    pos: 'DL',
    height: `6'9"`,
    weight: '310',
  },
  'Terrell Burgess': {
    name: 'Terrell Burgess',
    sch: 'Utah',
    pos: 'S',
    height: `6'0"`,
    weight: '194',
  },
  'Damon Arnette': {
    name: 'Damon Arnette',
    sch: 'Ohio State',
    pos: 'CB',
    height: `6'0"`,
    weight: '195',
  },
  'Gabriel Davis': {
    name: 'Gabriel Davis',
    sch: 'UCF',
    pos: 'WR',
    height: `6'3"`,
    weight: '212',
  },
  'A.J. Dillon': {
    name: 'A.J. Dillon',
    sch: 'Boston College',
    pos: 'RB',
    height: `6'0"`,
    weight: '245',
  },
  'Oluwole Betiku': {
    name: 'Oluwole Betiku',
    sch: 'Illinois',
    pos: 'EDGE',
    height: `6'3"`,
    weight: '240',
  },
  'Michael Ojemudia': {
    name: 'Michael Ojemudia',
    sch: 'Iowa',
    pos: 'S',
    height: `6'1"`,
    weight: '199',
  },
  'Darnay Holmes': {
    name: 'Darnay Holmes',
    sch: 'UCLA',
    pos: 'CB',
    height: `6'0"`,
    weight: '198',
  },
  'Anthony McFarland Jr.': {
    name: 'Anthony McFarland Jr.',
    sch: 'Maryland',
    pos: 'RB',
    height: `5'8"`,
    weight: '193',
  },
  'Mustafa Johnson': {
    name: 'Mustafa Johnson',
    sch: 'Colorado',
    pos: 'DL',
    height: `6'2"`,
    weight: '290',
  },
  'Simon Stepaniak': {
    name: 'Simon Stepaniak',
    sch: 'Indiana',
    pos: 'OG',
    height: `6'4"`,
    weight: '319',
  },
  'Lavert Hill': {
    name: 'Lavert Hill',
    sch: 'Michigan',
    pos: 'CB',
    height: `6'1"`,
    weight: '181',
  },
  'Chase Claypool': {
    name: 'Chase Claypool',
    sch: 'Notre Dame',
    pos: 'WR',
    height: `6'4"`,
    weight: '227',
  },
  'Terence Steele': {
    name: 'Terence Steele',
    sch: 'Texas Tech',
    pos: 'OT',
    height: `6'6"`,
    weight: '310',
  },
  'Benito Jones': {
    name: 'Benito Jones',
    sch: 'Ole Miss',
    pos: 'DL',
    height: `6'1"`,
    weight: '326',
  },
  'Harrison Bryant': {
    name: 'Harrison Bryant',
    sch: 'Florida Atlantic',
    pos: 'TE',
    height: `6'5"`,
    weight: '240',
  },
  'Jason Strowbridge': {
    name: 'Jason Strowbridge',
    sch: 'North Carolina',
    pos: 'DL',
    height: `6'3"`,
    weight: '267',
  },
  'Michael Onwenu': {
    name: 'Michael Onwenu',
    sch: 'Michigan',
    pos: 'OG',
    height: `6'3"`,
    weight: '350',
  },
  'Jashon Cornell': {
    name: 'Jashon Cornell',
    sch: 'Ohio State',
    pos: 'DL',
    height: `6'3"`,
    weight: '280',
  },
  'Amik Robertson': {
    name: 'Amik Robertson',
    sch: 'Louisiana Tech',
    pos: 'CB',
    height: `5'9"`,
    weight: '183',
  },
  'Delontae Scott': {
    name: 'Delontae Scott',
    sch: 'SMU',
    pos: 'EDGE',
    height: `6'5"`,
    weight: '250',
  },
  'Salvon Ahmed': {
    name: 'Salvon Ahmed',
    sch: 'Washington',
    pos: 'RB',
    height: `6'1"`,
    weight: '196',
  },
  'Alton Robinson': {
    name: 'Alton Robinson',
    sch: 'Syracuse',
    pos: 'EDGE',
    height: `6'4"`,
    weight: '257',
  },
  'Sean Pollard': {
    name: 'Sean Pollard',
    sch: 'Clemson',
    pos: 'OC',
    height: `6'5"`,
    weight: '320',
  },
  'Markus Bailey': {
    name: 'Markus Bailey',
    sch: 'Purdue',
    pos: 'LB',
    height: `6'1"`,
    weight: '240',
  },
  'J.R. Reed': {
    name: 'J.R. Reed',
    sch: 'Georgia',
    pos: 'S',
    height: `6'1"`,
    weight: '199',
  },
  'Isaiah Hodgins': {
    name: 'Isaiah Hodgins',
    sch: 'Oregon State',
    pos: 'WR',
    height: `6'4"`,
    weight: '209',
  },
  'Jabari Zuniga': {
    name: 'Jabari Zuniga',
    sch: 'Florida',
    pos: 'EDGE',
    height: `6'4"`,
    weight: '257',
  },
  'Brendon Hayes': {
    name: 'Brendon Hayes',
    sch: 'Central Florida',
    pos: 'DL',
    height: `6'3"`,
    weight: '293',
  },
  'Frederick Mauigoa': {
    name: 'Frederick Mauigoa',
    sch: 'Washington State',
    pos: 'OC',
    height: `6'3"`,
    weight: '310',
  },
  'Luq Barcoo': {
    name: 'Luq Barcoo',
    sch: 'San Diego State',
    pos: 'CB',
    height: `6'1"`,
    weight: '175',
  },
  'Joe Bachie': {
    name: 'Joe Bachie',
    sch: 'Michigan State',
    pos: 'LB',
    height: `6'2"`,
    weight: '238',
  },
  'Tyler Huntley': {
    name: 'Tyler Huntley',
    sch: 'Utah',
    pos: 'QB',
    height: `6'1"`,
    weight: '205',
  },
  'Javon Leake': {
    name: 'Javon Leake',
    sch: 'Maryland',
    pos: 'RB',
    height: `6'0"`,
    weight: '205',
  },
  'Antonio Gibson': {
    name: 'Antonio Gibson',
    sch: 'Mempis',
    pos: 'RB',
    height: `6'3"`,
    weight: '221',
  },
  'Alohi Gilman': {
    name: 'Alohi Gilman',
    sch: 'Notre Dame',
    pos: 'S',
    height: `6'1"`,
    weight: '201',
  },
  'Keandre Jones': {
    name: 'Keandre Jones',
    sch: 'Maryland',
    pos: 'LB',
    height: `6'2"`,
    weight: '228',
  },
  'Cam Brown': {
    name: 'Cam Brown',
    sch: 'Penn State',
    pos: 'LB',
    height: `6'5"`,
    weight: '230',
  },
  'Jared Holbers': {
    name: 'Jared Holbers',
    sch: 'Washington',
    pos: 'OT',
    height: `6'7"`,
    weight: '305',
  },
  'Chauncey Rivers': {
    name: 'Chauncey Rivers',
    sch: 'Mississippi State',
    pos: 'DL',
    height: `6'3"`,
    weight: '275',
  },
  'Benny LeMay': {
    name: 'Benny LeMay',
    sch: 'Charlotte',
    pos: 'RB',
    height: `6'0"`,
    weight: '218',
  },
  'Saahdiq Charles': {
    name: 'Saahdiq Charles',
    sch: 'LSU',
    pos: 'OT',
    height: `6'5"`,
    weight: '305',
  },
  'Bravvion Roy': {
    name: 'Bravvion Roy',
    sch: 'Baylor',
    pos: 'DL',
    height: `6'1"`,
    weight: '330',
  },
  'Nate Stanley': {
    name: 'Nate Stanley',
    sch: 'Iowa',
    pos: 'QB',
    height: `6'4"`,
    weight: '243',
  },
  'Lamical Perine': {
    name: 'Lamical Perine',
    sch: 'Florida',
    pos: 'RB',
    height: `6'1"`,
    weight: '227',
  },
  'Michael Divinity Jr.': {
    name: 'Michael Divinity Jr.',
    sch: 'LSU',
    pos: 'LB',
    height: `6'2"`,
    weight: '238',
  },
  "Benning Potoa'e": {
    name: "Benning Potoa'e",
    sch: 'Washington',
    pos: 'DL',
    height: `6'3"`,
    weight: '290',
  },
  'Jacob Breeland': {
    name: 'Jacob Breeland',
    sch: 'Oregon',
    pos: 'TE',
    height: `6'5"`,
    weight: '240',
  },
  'Francis Bernard': {
    name: 'Francis Bernard',
    sch: 'Utah State',
    pos: 'LB',
    height: `6'1"`,
    weight: '235',
  },
  'Darrynton Evans': {
    name: 'Darrynton Evans',
    sch: 'Appalachian State',
    pos: 'RB',
    height: `6'1"`,
    weight: '190',
  },
  'John Molchon': {
    name: 'John Molchon',
    sch: 'Boise State',
    pos: 'OG',
    height: `6'5"`,
    weight: '322',
  },
  'Matt Peart': {
    name: 'Matt Peart',
    sch: 'UConn',
    pos: 'OT',
    height: `6'7"`,
    weight: '303',
  },
  'Devin Asiasi': {
    name: 'Devin Asiasi',
    sch: 'UCLA',
    pos: 'TE',
    height: `6'3"`,
    weight: '279',
  },
  'Joshua Kelley': {
    name: 'Joshua Kelley',
    sch: 'UCLA',
    pos: 'RB',
    height: `6'1"`,
    weight: '204',
  },
  'Jace Whittaker': {
    name: 'Jace Whittaker',
    sch: 'Arizona',
    pos: 'CB',
    height: `6'1"`,
    weight: '185',
  },
  'Jessie Lemonier': {
    name: 'Jessie Lemonier',
    sch: 'Liberty',
    pos: 'EDGE',
    height: `6'3"`,
    weight: '240',
  },
  'Patrick Taylor Jr.': {
    name: 'Patrick Taylor Jr.',
    sch: 'Memphis',
    pos: 'RB',
    height: `6'3"`,
    weight: '227',
  },
  'Kalija Lipscomb': {
    name: 'Kalija Lipscomb',
    sch: 'Vanderbilt',
    pos: 'WR',
    height: `6'1"`,
    weight: '200',
  },
  'Stanford Samuels III': {
    name: 'Stanford Samuels III',
    sch: 'Florida State',
    pos: 'CB',
    height: `6'2"`,
    weight: '185',
  },
  'Keith Ismael': {
    name: 'Keith Ismael',
    sch: 'San Diego State',
    pos: 'OC',
    height: `6'3"`,
    weight: '311',
  },
  'D.J. Wonnum': {
    name: 'D.J. Wonnum',
    sch: 'South Carolina',
    pos: 'EDGE',
    height: `6'5"`,
    weight: '255',
  },
  'Michael Pinckney': {
    name: 'Michael Pinckney',
    sch: 'Miami (FL)',
    pos: 'LB',
    height: `6'1"`,
    weight: '220',
  },
  'Jonathan Garvin': {
    name: 'Jonathan Garvin',
    sch: 'Miami (FL)',
    pos: 'EDGE',
    height: `6'4"`,
    weight: '250',
  },
  'Reggie Robinson II': {
    name: 'Reggie Robinson II',
    sch: 'Tulsa',
    pos: 'CB',
    height: `6'1"`,
    weight: '197',
  },
  'Darius Anderson': {
    name: 'Darius Anderson',
    sch: 'TCU',
    pos: 'RB',
    height: `6'1"`,
    weight: '212',
  },
  'Scott Frantz': {
    name: 'Scott Frantz',
    sch: 'Kansas State',
    pos: 'OT',
    height: `6'5"`,
    weight: '303',
  },
  'John Hightower': {
    name: 'John Hightower',
    sch: 'Boise State',
    pos: 'WR',
    height: `6'2"`,
    weight: '184',
  },
  'Tyler Clark': {
    name: 'Tyler Clark',
    sch: 'Georgia',
    pos: 'DL',
    height: `6'4"`,
    weight: '300',
  },
  'Quintez Cephus': {
    name: 'Quintez Cephus',
    sch: 'Wisconsin',
    pos: 'WR',
    height: `6'1"`,
    weight: '207',
  },
  'Jon Runyan': {
    name: 'Jon Runyan',
    sch: 'Michigan',
    pos: 'OG',
    height: `6'5"`,
    weight: '310',
  },
  'Essang Bassey': {
    name: 'Essang Bassey',
    sch: 'Wake Forest',
    pos: 'S',
    height: `6'0"`,
    weight: '190',
  },
  'LaDarius Hamilton': {
    name: 'LaDarius Hamilton',
    sch: 'North Texas',
    pos: 'EDGE',
    height: `6'3"`,
    weight: '255',
  },
  'Adam Shuler': {
    name: 'Adam Shuler',
    sch: 'Florida',
    pos: 'DL',
    height: `6'4"`,
    weight: '275',
  },
  'Mohamed Barry': {
    name: 'Mohamed Barry',
    sch: 'Nebraska',
    pos: 'LB',
    height: `6'1"`,
    weight: '245',
  },
  'Julian Blackmon': {
    name: 'Julian Blackmon',
    sch: 'Utah',
    pos: 'S',
    height: `6'1"`,
    weight: '195',
  },
  'Levante Bellamy': {
    name: 'Levante Bellamy',
    sch: 'W Michigan',
    pos: 'RB',
    height: `5'9"`,
    weight: '185',
  },
  'Kendall Coleman': {
    name: 'Kendall Coleman',
    sch: 'Syracuse',
    pos: 'EDGE',
    height: `6'3"`,
    weight: '266',
  },
  'Adrian Killins Jr.': {
    name: 'Adrian Killins Jr.',
    sch: 'UCF',
    pos: 'RB',
    height: `5'8"`,
    weight: '164',
  },
  'Austin Edwards': {
    name: 'Austin Edwards',
    sch: 'Ferris State',
    pos: 'DL',
    height: `6'4"`,
    weight: '275',
  },
  'Levonta Taylor': {
    name: 'Levonta Taylor',
    sch: 'Florida State',
    pos: 'CB',
    height: `6'0"`,
    weight: '181',
  },
  'Matt Hennessy': {
    name: 'Matt Hennessy',
    sch: 'Temple',
    pos: 'OC',
    height: `6'4"`,
    weight: '295',
  },
  'Khalil Davis': {
    name: 'Khalil Davis',
    sch: 'Nebraska',
    pos: 'DL',
    height: `6'2"`,
    weight: '310',
  },
  'J.J. Taylor': {
    name: 'J.J. Taylor',
    sch: 'Arizona',
    pos: 'RB',
    height: `5'6"`,
    weight: '184',
  },
  'Geno Stone': {
    name: 'Geno Stone',
    sch: 'Iowa',
    pos: 'S',
    height: `6'0"`,
    weight: '210',
  },
  'David Woodward': {
    name: 'David Woodward',
    sch: 'Utah State',
    pos: 'LB',
    height: `6'2"`,
    weight: '235',
  },
  'Trey Sermon': {
    name: 'Trey Sermon',
    sch: 'Oklahoma',
    pos: 'RB',
    height: `6'0"`,
    weight: '224',
  },
  'Reggie Corbin': {
    name: 'Reggie Corbin',
    sch: 'Illinois',
    pos: 'RB',
    height: `6'0"`,
    weight: '200',
  },
  'Justin Strnad': {
    name: 'Justin Strnad',
    sch: 'Wake Forest',
    pos: 'LB',
    height: `6'3"`,
    weight: '230',
  },
  'Jeremy Chinn': {
    name: 'Jeremy Chinn',
    sch: 'S. Illinois',
    pos: 'S',
    height: `6'3"`,
    weight: '208',
  },
  'JaMycal Hasty': {
    name: 'JaMycal Hasty',
    sch: 'Baylor',
    pos: 'RB',
    height: `5'9"`,
    weight: '205',
  },
  'Sean Adesanya': {
    name: 'Sean Adesanya',
    sch: 'Central Michigan',
    pos: 'EDGE',
    height: `6'3"`,
    weight: '240',
  },
  'Tavien Feaster': {
    name: 'Tavien Feaster',
    sch: 'South Carolina',
    pos: 'RB',
    height: `6'1"`,
    weight: '220',
  },
  'James Morgan': {
    name: 'James Morgan',
    sch: 'Florida International',
    pos: 'QB',
    height: `6'4"`,
    weight: '213',
  },
  'Tanner Muse': {
    name: 'Tanner Muse',
    sch: 'Clemson',
    pos: 'S',
    height: `6'2"`,
    weight: '231',
  },
  'Kendrick Rogers': {
    name: 'Kendrick Rogers',
    sch: 'Texas A&M',
    pos: 'WR',
    height: `6'4"`,
    weight: '204',
  },
  'Jeremiah Dinson': {
    name: 'Jeremiah Dinson',
    sch: 'Auburn',
    pos: 'S',
    height: `6'0"`,
    weight: '191',
  },
  'John Houston Jr.': {
    name: 'John Houston Jr.',
    sch: 'USC',
    pos: 'LB',
    height: `6'3"`,
    weight: '220',
  },
  'Robert Windsor': {
    name: 'Robert Windsor',
    sch: 'Penn State',
    pos: 'DL',
    height: `6'4"`,
    weight: '289',
  },
  'Alex Taylor': {
    name: 'Alex Taylor',
    sch: 'South Carolina St',
    pos: 'OT',
    height: `6'9"`,
    weight: '305',
  },
  'Carlos Davis': {
    name: 'Carlos Davis',
    sch: 'Nebraska',
    pos: 'DL',
    height: `6'2"`,
    weight: '320',
  },
  'Aaron Crawford': {
    name: 'Aaron Crawford',
    sch: 'North Carolina',
    pos: 'DL',
    height: `6'2"`,
    weight: '290',
  },
  'Stephen Sullivan': {
    name: 'Stephen Sullivan',
    sch: 'LSU',
    pos: 'TE',
    height: `6'5"`,
    weight: '242',
  },
  'Zack Shackelford': {
    name: 'Zack Shackelford',
    sch: 'Texas',
    pos: 'OC',
    height: `6'4"`,
    weight: '305',
  },
  'Joe Reed': {
    name: 'Joe Reed',
    sch: 'Virginia',
    pos: 'WR',
    height: `6'1"`,
    weight: '215',
  },
  'Breiden Fehoko': {
    name: 'Breiden Fehoko',
    sch: 'LSU',
    pos: 'DL',
    height: `6'4"`,
    weight: '291',
  },
  'Eli Hanback': {
    name: 'Eli Hanback',
    sch: 'Virginia',
    pos: 'DL',
    height: `6'4"`,
    weight: '300',
  },
  'DeMarkus Acy': {
    name: 'DeMarkus Acy',
    sch: 'Missouri',
    pos: 'CB',
    height: `6'2"`,
    weight: '195',
  },
  'Lakiem Williams': {
    name: 'Lakiem Williams',
    sch: 'Syracuse',
    pos: 'LB',
    height: `6'0"`,
    weight: '225',
  },
  'Kenny Cooper': {
    name: 'Kenny Cooper',
    sch: 'Georgia Tech',
    pos: 'OC',
    height: `6'2"`,
    weight: '305',
  },
  'Nigel Warrior': {
    name: 'Nigel Warrior',
    sch: 'Tennessee',
    pos: 'S',
    height: `6'0"`,
    weight: '190',
  },
  'Josiah Scott': {
    name: 'Josiah Scott',
    sch: 'Michigan State',
    pos: 'CB',
    height: `6'0"`,
    weight: '168',
  },
  'Cole McDonald': {
    name: 'Cole McDonald',
    sch: 'Hawaii',
    pos: 'QB',
    height: `6'4"`,
    weight: '220',
  },
  'Dane Jackson': {
    name: 'Dane Jackson',
    sch: 'Pittsburgh',
    pos: 'CB',
    height: `6'0"`,
    weight: '190',
  },
  'Aaron Fuller': {
    name: 'Aaron Fuller',
    sch: 'Washington',
    pos: 'WR',
    height: `6'1"`,
    weight: '186',
  },
  'Kamren Curl': {
    name: 'Kamren Curl',
    sch: 'Arkansas',
    pos: 'S',
    height: `6'2"`,
    weight: '198',
  },
  'James Robinson': {
    name: 'James Robinson',
    sch: 'Illinois State',
    pos: 'RB',
    height: `6'0"`,
    weight: '220',
  },
  'Ben Bartch': {
    name: 'Ben Bartch',
    sch: "St. John's",
    pos: 'OG',
    height: `6'6"`,
    weight: '305',
  },
  'Kamal Martin': {
    name: 'Kamal Martin',
    sch: 'Minnesota',
    pos: 'LB',
    height: `6'3"`,
    weight: '235',
  },
  'Justin Herron': {
    name: 'Justin Herron',
    sch: 'Wake Forest',
    pos: 'OG',
    height: `6'4"`,
    weight: '290',
  },
  'Nick Coe': {
    name: 'Nick Coe',
    sch: 'Auburn',
    pos: 'DL',
    height: `6'5"`,
    weight: '282',
  },
  'Trevon McSwain': {
    name: 'Trevon McSwain',
    sch: 'Duke',
    pos: 'DL',
    height: `6'6"`,
    weight: '285',
  },
  'Javelin Guidry': {
    name: 'Javelin Guidry',
    sch: 'Utah',
    pos: 'CB',
    height: `5'9"`,
    weight: '193',
  },
  'Jake Luton': {
    name: 'Jake Luton',
    sch: 'Oregon State',
    pos: 'QB',
    height: `6'7"`,
    weight: '229',
  },
  'Jo-El Shaw': {
    name: 'Jo-El Shaw',
    sch: 'Kent State',
    pos: 'RB',
    height: `6'1"`,
    weight: '230',
  },
  'Dontavious Jackson': {
    name: 'Dontavious Jackson',
    sch: 'Florida State',
    pos: 'LB',
    height: `6'3"`,
    weight: '233',
  },
  'Myles Dorn': {
    name: 'Myles Dorn',
    sch: 'North Carolina',
    pos: 'S',
    height: `6'1"`,
    weight: '198',
  },
  'T.J. McCoy': {
    name: 'T.J. McCoy',
    sch: 'Louisville',
    pos: 'OC',
    height: `6'1"`,
    weight: '315',
  },
  'Lynn Bowden Jr.': {
    name: 'Lynn Bowden Jr.',
    sch: 'Kentucky',
    pos: 'WR',
    height: `6'1"`,
    weight: '199',
  },
  'Kyle Markway': {
    name: 'Kyle Markway',
    sch: 'South Carolina',
    pos: 'TE',
    height: `6'4"`,
    weight: '250',
  },
  'Jack Driscoll': {
    name: 'Jack Driscoll',
    sch: 'Auburn',
    pos: 'OT',
    height: `6'5"`,
    weight: '296',
  },
  'Eric Lee Jr.': {
    name: 'Eric Lee Jr.',
    sch: 'Nebraska',
    pos: 'S',
    height: `6'0"`,
    weight: '215',
  },
  'Asmar Bilal': {
    name: 'Asmar Bilal',
    sch: 'Notre Dame',
    pos: 'LB',
    height: `6'2"`,
    weight: '227',
  },
  'Charlie Heck': {
    name: 'Charlie Heck',
    sch: 'North Carolina',
    pos: 'OT',
    height: `6'8"`,
    weight: '310',
  },
  'Kylan Johnson': {
    name: 'Kylan Johnson',
    sch: 'Pitt',
    pos: 'LB',
    height: `6'2"`,
    weight: '230',
  },
  'Casey Toohill': {
    name: 'Casey Toohill',
    sch: 'Stanford',
    pos: 'EDGE',
    height: `6'4"`,
    weight: '247',
  },
  'Shaun BradLey': {
    name: 'Shaun BradLey',
    sch: 'Temple',
    pos: 'LB',
    height: `6'1"`,
    weight: '225',
  },
  'Derrek Tuszka': {
    name: 'Derrek Tuszka',
    sch: 'North Dakota State',
    pos: 'EDGE',
    height: `6'5"`,
    weight: '246',
  },
  "Ty'Son Williams": {
    name: "Ty'Son Williams",
    sch: 'BYU',
    pos: 'RB',
    height: `6'0"`,
    weight: '220',
  },
  'Tae Crowder': {
    name: 'Tae Crowder',
    sch: 'Georgia',
    pos: 'LB',
    height: `6'3"`,
    weight: '235',
  },
  'Logan Wilson': {
    name: 'Logan Wilson',
    sch: 'Wyoming',
    pos: 'LB',
    height: `6'2"`,
    weight: '250',
  },
  'Khaleke Hudson': {
    name: 'Khaleke Hudson',
    sch: 'Michigan',
    pos: 'LB',
    height: `6'0"`,
    weight: '220',
  },
  'Xavier Jones': {
    name: 'Xavier Jones',
    sch: 'SMU',
    pos: 'RB',
    height: `6'1"`,
    weight: '208',
  },
  'Chad Smith': {
    name: 'Chad Smith',
    sch: 'Clemson',
    pos: 'LB',
    height: `6'3"`,
    weight: '240',
  },
  'Josiah Deguara': {
    name: 'Josiah Deguara',
    sch: 'Cincinnati',
    pos: 'TE',
    height: `6'3"`,
    weight: '240',
  },
  'Nevelle Clarke': {
    name: 'Nevelle Clarke',
    sch: 'Central Florida',
    pos: 'CB',
    height: `6'1"`,
    weight: '187',
  },
  'John Reid': {
    name: 'John Reid',
    sch: 'Penn State',
    pos: 'CB',
    height: `6'1"`,
    weight: '184',
  },
  'David Dowell': {
    name: 'David Dowell',
    sch: 'Michigan State',
    pos: 'S',
    height: `6'1"`,
    weight: '205',
  },
  'Dominick Wood-Anderson': {
    name: 'Dominick Wood-Anderson',
    sch: 'Tennessee',
    pos: 'TE',
    height: `6'4"`,
    weight: '257',
  },
  'Dominique Ross': {
    name: 'Dominique Ross',
    sch: 'North Carolina',
    pos: 'LB',
    height: `6'4"`,
    weight: '228',
  },
  'Marquez Callaway': {
    name: 'Marquez Callaway',
    sch: 'Tennessee',
    pos: 'WR',
    height: `6'2"`,
    weight: '200',
  },
  'George Lea': {
    name: 'George Lea',
    sch: 'Arizona State',
    pos: 'DL',
    height: `6'3"`,
    weight: '275',
  },
  'Travion Banks': {
    name: 'Travion Banks',
    sch: 'Miami (OH)',
    pos: 'CB',
    height: `6'1"`,
    weight: '184',
  },
  'Jeff Thomas': {
    name: 'Jeff Thomas',
    sch: 'Miami',
    pos: 'WR',
    height: `6'0"`,
    weight: '180',
  },
  'Marcel Spears Jr.': {
    name: 'Marcel Spears Jr.',
    sch: 'Iowa State',
    pos: 'LB',
    height: `6'1"`,
    weight: '215',
  },
  'Robert Landers': {
    name: 'Robert Landers',
    sch: 'Ohio State',
    pos: 'DL',
    height: `6'1"`,
    weight: '285',
  },
  'Bryce Perkins': {
    name: 'Bryce Perkins',
    sch: 'Virginia',
    pos: 'QB',
    height: `6'3"`,
    weight: '215',
  },
};

export default players;
