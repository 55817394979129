const teamNeeds = {
  ARI: {
    LB: { wt: 1.0, total: 2, drafted: 0 },
    OL: { wt: 0.9, total: 5, drafted: 0 },
    DL: { wt: 0.8, total: 2, drafted: 0 },
    RB: { wt: 0.7, total: 2, drafted: 0 },
    WR: { wt: 0.6, total: 3, drafted: 0 },
    EDGE: { wt: 0.5, total: 2, drafted: 0 },
    CB: { wt: 0.4, total: 2, drafted: 0 },
    S: { wt: 0.3, total: 2, drafted: 0 },
    TE: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },

  ATL: {
    EDGE: { wt: 1, total: 2, drafted: 0 },
    LB: { wt: 0.9, total: 2, drafted: 0 },
    CB: { wt: 0.8, total: 2, drafted: 0 },
    DL: { wt: 0.7, total: 2, drafted: 0 },
    OL: { wt: 0.6, total: 5, drafted: 0 },
    RB: { wt: 0.5, total: 2, drafted: 0 },
    TE: { wt: 0.4, total: 2, drafted: 0 },
    WR: { wt: 0.3, total: 3, drafted: 0 },
    S: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  BAL: {
    LB: { wt: 1, total: 2, drafted: 0 },
    WR: { wt: 0.9, total: 3, drafted: 0 },
    EDGE: { wt: 0.8, total: 2, drafted: 0 },
    DL: { wt: 0.7, total: 2, drafted: 0 },
    OL: { wt: 0.6, total: 5, drafted: 0 },
    S: { wt: 0.5, total: 2, drafted: 0 },
    CB: { wt: 0.4, total: 2, drafted: 0 },
    RB: { wt: 0.3, total: 2, drafted: 0 },
    TE: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  BUF: {
    EDGE: { wt: 0.9, total: 2, drafted: 0 },
    LB: { wt: 0.8, total: 2, drafted: 0 },
    CB: { wt: 0.7, total: 2, drafted: 0 },
    OL: { wt: 0.6, total: 5, drafted: 0 },
    RB: { wt: 0.5, total: 2, drafted: 0 },
    DL: { wt: 0.4, total: 2, drafted: 0 },
    WR: { wt: 0.3, total: 3, drafted: 0 },
    S: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
    TE: { wt: 0.1, total: 2, drafted: 0 },
  },
  CAR: {
    DL: { wt: 1, total: 2, drafted: 0 },
    CB: { wt: 0.9, total: 2, drafted: 0 },
    LB: { wt: 0.8, total: 2, drafted: 0 },
    S: { wt: 0.7, total: 2, drafted: 0 },
    QB: { wt: 0.6, total: 1, drafted: 0 },
    OL: { wt: 0.5, total: 5, drafted: 0 },
    TE: { wt: 0.4, total: 2, drafted: 0 },
    EDGE: { wt: 0.3, total: 2, drafted: 0 },
    WR: { wt: 0.2, total: 3, drafted: 0 },
    RB: { wt: 0.1, total: 2, drafted: 0 },
  },
  CHI: {
    OL: { wt: 1, total: 5, drafted: 0 },
    TE: { wt: 0.9, total: 2, drafted: 0 },
    LB: { wt: 0.8, total: 2, drafted: 0 },
    S: { wt: 0.7, total: 2, drafted: 0 },
    CB: { wt: 0.6, total: 2, drafted: 0 },
    EDGE: { wt: 0.5, total: 2, drafted: 0 },
    WR: { wt: 0.4, total: 3, drafted: 0 },
    QB: { wt: 0.3, total: 1, drafted: 0 },
    DL: { wt: 0.2, total: 2, drafted: 0 },
    RB: { wt: 0.1, total: 2, drafted: 0 },
  },
  CIN: {
    QB: { wt: 1, total: 1, drafted: 0 },
    WR: { wt: 0.9, total: 3, drafted: 0 },
    EDGE: { wt: 0.8, total: 2, drafted: 0 },
    LB: { wt: 0.7, total: 2, drafted: 0 },
    OL: { wt: 0.6, total: 5, drafted: 0 },
    RB: { wt: 0.5, total: 2, drafted: 0 },
    DL: { wt: 0.4, total: 2, drafted: 0 },
    CB: { wt: 0.3, total: 2, drafted: 0 },
    TE: { wt: 0.2, total: 2, drafted: 0 },
    S: { wt: 0, total: 2, drafted: 0 },
  },
  CLE: {
    OL: { wt: 1, total: 5, drafted: 0 },
    WR: { wt: 0.9, total: 3, drafted: 0 },
    CB: { wt: 0.8, total: 2, drafted: 0 },
    LB: { wt: 0.7, total: 2, drafted: 0 },
    S: { wt: 0.6, total: 2, drafted: 0 },
    QB: { wt: 0.5, total: 1, drafted: 0 },
    EDGE: { wt: 0.4, total: 2, drafted: 0 },
    DL: { wt: 0.3, total: 2, drafted: 0 },
    TE: { wt: 0.2, total: 2, drafted: 0 },
    RB: { wt: 0.1, total: 2, drafted: 0 },
  },
  DAL: {
    S: { wt: 1, total: 2, drafted: 0 },
    WR: { wt: 0.9, total: 3, drafted: 0 },
    CB: { wt: 0.8, total: 2, drafted: 0 },
    DL: { wt: 0.7, total: 2, drafted: 0 },
    EDGE: { wt: 0.6, total: 2, drafted: 0 },
    TE: { wt: 0.5, total: 2, drafted: 0 },
    OL: { wt: 0.4, total: 5, drafted: 0 },
    LB: { wt: 0.3, total: 2, drafted: 0 },
    RB: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  DEN: {
    WR: { wt: 1, total: 3, drafted: 0 },
    OL: { wt: 0.9, total: 5, drafted: 0 },
    CB: { wt: 0.8, total: 2, drafted: 0 },
    DL: { wt: 0.7, total: 2, drafted: 0 },
    EDGE: { wt: 0.6, total: 2, drafted: 0 },
    LB: { wt: 0.5, total: 2, drafted: 0 },
    RB: { wt: 0.4, total: 2, drafted: 0 },
    S: { wt: 0.3, total: 2, drafted: 0 },
    QB: { wt: 0.2, total: 1, drafted: 0 },
    TE: { wt: 0.1, total: 2, drafted: 0 },
    //WR: {wt: .2, total: 3, drafted: 0},
  },
  DET: {
    CB: { wt: 1, total: 2, drafted: 0 },
    DL: { wt: 0.9, total: 2, drafted: 0 },
    EDGE: { wt: 0.8, total: 2, drafted: 0 },
    QB: { wt: 0.7, total: 1, drafted: 0 },
    OL: { wt: 0.6, total: 5, drafted: 0 },
    S: { wt: 0.5, total: 2, drafted: 0 },
    RB: { wt: 0.4, total: 2, drafted: 0 },
    WR: { wt: 0.3, total: 3, drafted: 0 },
    LB: { wt: 0.2, total: 2, drafted: 0 },
    TE: { wt: 0.1, total: 2, drafted: 0 },
  },
  GB: {
    WR: { wt: 1.0, total: 3, drafted: 0 },
    TE: { wt: 0.9, total: 2, drafted: 0 },
    OL: { wt: 0.8, total: 5, drafted: 0 },
    LB: { wt: 0.7, total: 2, drafted: 0 },
    EDGE: { wt: 0.6, total: 2, drafted: 0 },
    DL: { wt: 0.5, total: 2, drafted: 0 },
    CB: { wt: 0.4, total: 2, drafted: 0 },
    S: { wt: 0.3, total: 2, drafted: 0 },
    RB: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  HOU: {
    CB: { wt: 1, total: 2, drafted: 0 },
    LB: { wt: 0.9, total: 2, drafted: 0 },
    DL: { wt: 0.8, total: 2, drafted: 0 },
    RB: { wt: 0.7, total: 2, drafted: 0 },
    S: { wt: 0.6, total: 2, drafted: 0 },
    EDGE: { wt: 0.5, total: 2, drafted: 0 },
    OL: { wt: 0.4, total: 5, drafted: 0 },
    WR: { wt: 0.3, total: 3, drafted: 0 },
    TE: { wt: 0.3, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  IND: {
    WR: { wt: 1, total: 3, drafted: 0 },
    CB: { wt: 0.9, total: 2, drafted: 0 },
    EDGE: { wt: 0.8, total: 2, drafted: 0 },
    DL: { wt: 0.7, total: 2, drafted: 0 },
    TE: { wt: 0.6, total: 2, drafted: 0 },
    OL: { wt: 0.5, total: 5, drafted: 0 },
    QB: { wt: 0.4, total: 1, drafted: 0 },
    RB: { wt: 0.3, total: 2, drafted: 0 },
    S: { wt: 0.2, total: 2, drafted: 0 },
    LB: { wt: 0.1, total: 2, drafted: 0 },
  },
  JAX: {
    CB: { wt: 1.0, total: 2, drafted: 0 },
    LB: { wt: 0.9, total: 2, drafted: 0 },
    DL: { wt: 0.8, total: 2, drafted: 0 },
    OL: { wt: 0.7, total: 5, drafted: 0 },
    TE: { wt: 0.6, total: 2, drafted: 0 },
    S: { wt: 0.5, total: 2, drafted: 0 },
    EDGE: { wt: 0.4, total: 2, drafted: 0 },
    RB: { wt: 0.3, total: 2, drafted: 0 },
    WR: { wt: 0.2, total: 3, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  KC: {
    CB: { wt: 1, total: 2, drafted: 0 },
    OL: { wt: 0.9, total: 5, drafted: 0 },
    RB: { wt: 0.8, total: 2, drafted: 0 },
    DL: { wt: 0.7, total: 2, drafted: 0 },
    EDGE: { wt: 0.6, total: 2, drafted: 0 },
    WR: { wt: 0.5, total: 3, drafted: 0 },
    S: { wt: 0.4, total: 2, drafted: 0 },
    LB: { wt: 0.3, total: 2, drafted: 0 },
    TE: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0, total: 1, drafted: 0 },
  },
  LAC: {
    QB: { wt: 1, total: 1, drafted: 0 },
    OL: { wt: 0.9, total: 5, drafted: 0 },
    RB: { wt: 0.8, total: 2, drafted: 0 },
    CB: { wt: 0.7, total: 2, drafted: 0 },
    WR: { wt: 0.6, total: 3, drafted: 0 },
    TE: { wt: 0.5, total: 2, drafted: 0 },
    DL: { wt: 0.4, total: 2, drafted: 0 },
    LB: { wt: 0.3, total: 2, drafted: 0 },
    EDGE: { wt: 0.2, total: 2, drafted: 0 },
    S: { wt: 0.1, total: 2, drafted: 0 },
  },
  LAR: {
    OL: { wt: 1, total: 5, drafted: 0 },
    LB: { wt: 0.9, total: 2, drafted: 0 },
    S: { wt: 0.8, total: 2, drafted: 0 },
    DL: { wt: 0.7, total: 2, drafted: 0 },
    EDGE: { wt: 0.6, total: 2, drafted: 0 },
    RB: { wt: 0.5, total: 2, drafted: 0 },
    WR: { wt: 0.4, total: 3, drafted: 0 },
    TE: { wt: 0.3, total: 2, drafted: 0 },
    CB: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  LV: {
    WR: { wt: 1, total: 3, drafted: 0 },
    LB: { wt: 0.9, total: 2, drafted: 0 },
    CB: { wt: 0.8, total: 2, drafted: 0 },
    QB: { wt: 0.7, total: 1, drafted: 0 },
    S: { wt: 0.6, total: 2, drafted: 0 },
    OL: { wt: 0.5, total: 5, drafted: 0 },
    DL: { wt: 0.4, total: 2, drafted: 0 },
    EDGE: { wt: 0.3, total: 2, drafted: 0 },
    RB: { wt: 0.2, total: 2, drafted: 0 },
    TE: { wt: 0.1, total: 2, drafted: 0 },
  },
  MIA: {
    QB: { wt: 1, total: 1, drafted: 0 },
    OL: { wt: 0.9, total: 5, drafted: 0 },
    DL: { wt: 0.8, total: 2, drafted: 0 },
    EDGE: { wt: 0.7, total: 2, drafted: 0 },
    RB: { wt: 0.6, total: 2, drafted: 0 },
    CB: { wt: 0.5, total: 2, drafted: 0 },
    WR: { wt: 0.4, total: 3, drafted: 0 },
    TE: { wt: 0.3, total: 2, drafted: 0 },
    S: { wt: 0.2, total: 2, drafted: 0 },
    LB: { wt: 0.1, total: 2, drafted: 0 },
  },
  MIN: {
    CB: { wt: 1, total: 2, drafted: 0 },
    WR: { wt: 0.9, total: 3, drafted: 0 },
    S: { wt: 0.8, total: 2, drafted: 0 },
    OL: { wt: 0.7, total: 5, drafted: 0 },
    DL: { wt: 0.6, total: 2, drafted: 0 },
    LB: { wt: 0.5, total: 2, drafted: 0 },
    QB: { wt: 0.4, total: 1, drafted: 0 },
    EDGE: { wt: 0.3, total: 2, drafted: 0 },
    TE: { wt: 0.2, total: 2, drafted: 0 },
    RB: { wt: 0.1, total: 2, drafted: 0 },
  },
  NE: {
    QB: { wt: 1, total: 1, drafted: 0 },
    TE: { wt: 0.9, total: 2, drafted: 0 },
    DL: { wt: 0.8, total: 2, drafted: 0 },
    OL: { wt: 0.7, total: 5, drafted: 0 },
    S: { wt: 0.6, total: 2, drafted: 0 },
    EDGE: { wt: 0.5, total: 2, drafted: 0 },
    LB: { wt: 0.4, total: 2, drafted: 0 },
    WR: { wt: 0.3, total: 3, drafted: 0 },
    CB: { wt: 0.2, total: 2, drafted: 0 },
    RB: { wt: 0.1, total: 2, drafted: 0 },
  },
  NO: {
    WR: { wt: 1, total: 3, drafted: 0 },
    OL: { wt: 0.9, total: 5, drafted: 0 },
    CB: { wt: 0.8, total: 2, drafted: 0 },
    LB: { wt: 0.7, total: 2, drafted: 0 },
    DL: { wt: 0.6, total: 2, drafted: 0 },
    EDGE: { wt: 0.5, total: 2, drafted: 0 },
    TE: { wt: 0.4, total: 2, drafted: 0 },
    RB: { wt: 0.3, total: 2, drafted: 0 },
    S: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  NYG: {
    OL: { wt: 1, total: 2, drafted: 0 },
    LB: { wt: 0.9, total: 5, drafted: 0 },
    CB: { wt: 0.8, total: 2, drafted: 0 },
    S: { wt: 0.7, total: 2, drafted: 0 },
    WR: { wt: 0.6, total: 3, drafted: 0 },
    EDGE: { wt: 0.5, total: 2, drafted: 0 },
    RB: { wt: 0.4, total: 2, drafted: 0 },
    DL: { wt: 0.3, total: 2, drafted: 0 },
    TE: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  NYJ: {
    OL: { wt: 1, total: 5, drafted: 0 },
    WR: { wt: 0.9, total: 3, drafted: 0 },
    CB: { wt: 0.8, total: 2, drafted: 0 },
    LB: { wt: 0.7, total: 2, drafted: 0 },
    EDGE: { wt: 0.6, total: 2, drafted: 0 },
    RB: { wt: 0.5, total: 2, drafted: 0 },
    DL: { wt: 0.4, total: 2, drafted: 0 },
    TE: { wt: 0.3, total: 2, drafted: 0 },
    S: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  PHI: {
    WR: { wt: 1, total: 3, drafted: 0 },
    CB: { wt: 0.9, total: 2, drafted: 0 },
    LB: { wt: 0.8, total: 2, drafted: 0 },
    S: { wt: 0.7, total: 2, drafted: 0 },
    DL: { wt: 0.6, total: 2, drafted: 0 },
    OL: { wt: 0.5, total: 5, drafted: 0 },
    RB: { wt: 0.4, total: 2, drafted: 0 },
    EDGE: { wt: 0.3, total: 2, drafted: 0 },
    TE: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  PIT: {
    WR: { wt: 1.0, total: 3, drafted: 0 },
    LB: { wt: 0.9, total: 2, drafted: 0 },
    OL: { wt: 0.8, total: 5, drafted: 0 },
    QB: { wt: 0.7, total: 1, drafted: 0 },
    TE: { wt: 0.6, total: 2, drafted: 0 },
    CB: { wt: 0.5, total: 2, drafted: 0 },
    EDGE: { wt: 0.4, total: 2, drafted: 0 },
    RB: { wt: 0.3, total: 2, drafted: 0 },
    S: { wt: 0.2, total: 2, drafted: 0 },
    DL: { wt: 0.1, total: 2, drafted: 0 },
  },
  SEA: {
    EDGE: { wt: 1, total: 2, drafted: 0 },
    DL: { wt: 0.9, total: 2, drafted: 0 },
    WR: { wt: 0.8, total: 3, drafted: 0 },
    OL: { wt: 0.7, total: 5, drafted: 0 },
    S: { wt: 0.6, total: 2, drafted: 0 },
    LB: { wt: 0.5, total: 2, drafted: 0 },
    CB: { wt: 0.4, total: 2, drafted: 0 },
    RB: { wt: 0.3, total: 2, drafted: 0 },
    TE: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  SF: {
    DL: { wt: 1, total: 2, drafted: 0 },
    S: { wt: 0.9, total: 2, drafted: 0 },
    CB: { wt: 0.8, total: 2, drafted: 0 },
    OL: { wt: 0.7, total: 5, drafted: 0 },
    LB: { wt: 0.6, total: 2, drafted: 0 },
    WR: { wt: 0.5, total: 3, drafted: 0 },
    RB: { wt: 0.4, total: 2, drafted: 0 },
    EDGE: { wt: 0.3, total: 2, drafted: 0 },
    TE: { wt: 0.2, total: 2, drafted: 0 },
    QB: { wt: 0.1, total: 1, drafted: 0 },
  },
  TB: {
    DL: { wt: 1, total: 2, drafted: 0 },
    OL: { wt: 0.9, total: 5, drafted: 0 },
    LB: { wt: 0.8, total: 2, drafted: 0 },
    RB: { wt: 0.7, total: 2, drafted: 0 },
    EDGE: { wt: 0.6, total: 2, drafted: 0 },
    QB: { wt: 0.5, total: 1, drafted: 0 },
    CB: { wt: 0.4, total: 2, drafted: 0 },
    S: { wt: 0.3, total: 2, drafted: 0 },
    WR: { wt: 0.2, total: 3, drafted: 0 },
    TE: { wt: 0.1, total: 2, drafted: 0 },
  },
  TEN: {
    LB: { wt: 1, total: 2, drafted: 0 },
    OL: { wt: 0.9, total: 5, drafted: 0 },
    DL: { wt: 0.8, total: 2, drafted: 0 },
    TE: { wt: 0.7, total: 2, drafted: 0 },
    EDGE: { wt: 0.6, total: 2, drafted: 0 },
    RB: { wt: 0.5, total: 2, drafted: 0 },
    QB: { wt: 0.4, total: 1, drafted: 0 },
    WR: { wt: 0.3, total: 3, drafted: 0 },
    CB: { wt: 0.2, total: 2, drafted: 0 },
    S: { wt: 0.1, total: 2, drafted: 0 },
  },
  WAS: {
    EDGE: { wt: 1, total: 2, drafted: 0 },
    OL: { wt: 0.9, total: 5, drafted: 0 },
    LB: { wt: 0.8, total: 2, drafted: 0 },
    CB: { wt: 0.7, total: 2, drafted: 0 },
    TE: { wt: 0.6, total: 2, drafted: 0 },
    RB: { wt: 0.5, total: 2, drafted: 0 },
    S: { wt: 0.4, total: 2, drafted: 0 },
    WR: { wt: 0.3, total: 3, drafted: 0 },
    QB: { wt: 0.2, total: 1, drafted: 0 },
    DL: { wt: 0.1, total: 2, drafted: 0 },
  },
};

export default teamNeeds;
