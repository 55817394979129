/* eslint-disable quotes */
const draftboard = [
  'Joe Burrow',
  'Chase Young',
  'Derrick Brown',
  'Andrew Thomas',
  'CeeDee Lamb',
  'Jeffrey Okudah',
  'Isaiah Simmons',
  'Justin Herbert',
  'A.J. Epenesa',
  'Jedrick Wills',
  'Tristan Wirfs',
  'Tua Tagovailoa',
  'Jerry Jeudy',
  'Kristian Fulton',
  'Javon Kinlaw',
  'Grant Delpit',
  'Yetur Gross-Matos',
  'Austin Jackson',
  "K'Lavon Chaisson",
  'Tee Higgins',
  'Solomon KinDLey',
  'Trevon Diggs',
  'Clyde Edwards-Helaire',
  'Curtis Weaver',
  'Tyler Biadasz',
  'Xavier McKinney',
  'Jonathan Taylor',
  'Trey Adams',
  'Henry Ruggs III',
  'Cameron Dantzler',
  "D'Andre Swift",
  'Laviska Shenault',
  'C.J. Henderson',
  'Mekhi Becton',
  'Brycen Hopkins',
  'Jeff Gladney',
  'J.K. Dobbins',
  'Josh Jones',
  'Justin Jefferson',
  'Lloyd Cushenberry',
  'Neville Gallimore',
  'Bryce Hall',
  'Kenneth Murray',
  'Adam Trautman',
  'K.J. Hamler',
  'Prince Tega Wanogho',
  'James Lynch',
  'Zack Baun',
  'John Simpson',
  'Jake Fromm',
  'Jalen Reagor',
  'Yasir Durant',
  'Shane Lemieux',
  'Terrell Lewis',
  'Jordan Love',
  'A.J. Terrell',
  'Brandon Aiyuk',
  'Patrick Queen',
  'Michael Pittman Jr.',
  'Tyler Johnson',
  'Raekwon Davis',
  'Ben Bredeson',
  'Justin Madubuike',
  'Malik Harrison',
  'Antoine Winfield Jr.',
  'Marlon Davidson',
  'Jacob Eason',
  'Jacob Phillips',
  'Cesar Ruiz',
  'Dele Harding',
  'Jaylon Johnson',
  'Jalen Hurts',
  'Troy Dye',
  'Isaiah Wilson',
  'Albert Okwuegbunam',
  'Julian Okwara',
  'Anfernee Jennings',
  'Hakeem Adeniji',
  'Hunter Bryant',
  'A.J. Green',
  'K.J. Hill',
  'Cam Akers',
  "K'Von Wallace",
  'Jordyn Brooks',
  'BraDLee Anae',
  'Shyheim Carter',
  'Donovan Peoples-Jones',
  'Brandon Jones',
  'Colby Parkinson',
  'Larrell Murchison',
  'Calvin Throckmorton',
  'Rashard Lawrence',
  'Denzel Mims',
  'Logan Stenberg',
  'Ashtyn Davis',
  'Devin Duvernay',
  'Josh Uche',
  'Darryl Williams',
  'Ross Blacklock',
  'Leki Fotu',
  'Cole Kmet',
  'James Proche',
  'Collin Johnson',
  'Akeem Davis-Gaither',
  'Netane Muti',
  'Lamar Jackson',
  'Trevis Gipson',
  'Zack Moss',
  'Kenny Willekes',
  'Jonathan Greenard',
  'Anthony Gordon',
  'Antonio Gandy-Golden',
  'Davon Hamilton',
  'Joe Gaziano',
  "Ke'Shawn Vaughn",
  'Jordan Elliott',
  'Jake Hanson',
  'Darrell Taylor',
  'Troy Pride Jr.',
  'Jordan Mack',
  'Eno Benjamin',
  'Bryan Edwards',
  'Thaddeus Moss',
  'Khalid Kareem',
  'Lucas Niang',
  'Jauan Jennings',
  'Jonah Jackson',
  'Noah Igbinoghene',
  'McTelvin Agim',
  'Robert Hunt',
  'Antoine Brooks Jr.',
  'Raequan Williams',
  'Damien Lewis',
  'Nick Harris',
  'DeeJay Dallas',
  'Chris Orr',
  'Quartney Davis',
  'Kyle Dugger',
  'Trevon Hill',
  'Evan Weaver',
  'Van Jefferson',
  'Ezra Cleveland',
  'Alex Highsmith',
  'Shaquille Quarterman',
  'Calvin Taylor Jr.',
  'Terrell Burgess',
  'Damon Arnette',
  'Gabriel Davis',
  'A.J. Dillon',
  'Oluwole Betiku',
  'Michael Ojemudia',
  'Darnay Holmes',
  'Anthony McFarland Jr.',
  'Mustafa Johnson',
  'Simon Stepaniak',
  'Lavert Hill',
  'Chase Claypool',
  'Terence Steele',
  'Benito Jones',
  'Harrison Bryant',
  'Jason Strowbridge',
  'Michael Onwenu',
  'Jashon Cornell',
  'Amik Robertson',
  'Delontae Scott',
  'Salvon Ahmed',
  'Alton Robinson',
  'Sean Pollard',
  'Markus Bailey',
  'J.R. Reed',
  'Isaiah Hodgins',
  'Jabari Zuniga',
  'Brendon Hayes',
  'Frederick Mauigoa',
  'Luq Barcoo',
  'Joe Bachie',
  'Tyler Huntley',
  'Javon Leake',
  'Antonio Gibson',
  'Alohi Gilman',
  'Keandre Jones',
  'Cam Brown',
  'Jared Holbers',
  'Chauncey Rivers',
  'Benny LeMay',
  'Saahdiq Charles',
  'Bravvion Roy',
  'Nate Stanley',
  'Lamical Perine',
  'Michael Divinity Jr.',
  "Benning Potoa'e",
  'Jacob Breeland',
  'Francis Bernard',
  'Darrynton Evans',
  'John Molchon',
  'Matt Peart',
  'Devin Asiasi',
  'Joshua Kelley',
  'Jace Whittaker',
  'Jessie Lemonier',
  'Patrick Taylor Jr.',
  'Kalija Lipscomb',
  'Stanford Samuels III',
  'Keith Ismael',
  'D.J. Wonnum',
  'Michael Pinckney',
  'Jonathan Garvin',
  'Reggie Robinson II',
  'Darius Anderson',
  'Scott Frantz',
  'John Hightower',
  'Tyler Clark',
  'Quintez Cephus',
  'Jon Runyan',
  'Essang Bassey',
  'LaDarius Hamilton',
  'Adam Shuler',
  'Mohamed Barry',
  'Julian Blackmon',
  'Levante Bellamy',
  'Kendall Coleman',
  'Adrian Killins Jr.',
  'Austin Edwards',
  'Levonta Taylor',
  'Matt Hennessy',
  'Khalil Davis',
  'J.J. Taylor',
  'Geno Stone',
  'David Woodward',
  'Trey Sermon',
  'Reggie Corbin',
  'Justin Strnad',
  'Jeremy Chinn',
  'JaMycal Hasty',
  'Sean Adesanya',
  'Tavien Feaster',
  'James Morgan',
  'Tanner Muse',
  'Kendrick Rogers',
  'Jeremiah Dinson',
  'John Houston Jr.',
  'Robert Windsor',
  'Alex Taylor',
  'Carlos Davis',
  'Aaron Crawford',
  'Stephen Sullivan',
  'Zack Shackelford',
  'Joe Reed',
  'Breiden Fehoko',
  'Eli Hanback',
  'DeMarkus Acy',
  'Lakiem Williams',
  'Kenny Cooper',
  'Nigel Warrior',
  'Josiah Scott',
  'Cole McDonald',
  'Dane Jackson',
  'Aaron Fuller',
  'Kamren Curl',
  'James Robinson',
  'Ben Bartch',
  'Kamal Martin',
  'Justin Herron',
  'Nick Coe',
  'Trevon McSwain',
  'Javelin Guidry',
  'Jake Luton',
  'Jo-El Shaw',
  'Dontavious Jackson',
  'Myles Dorn',
  'T.J. McCoy',
  'Lynn Bowden Jr.',
  'Kyle Markway',
  'Jack Driscoll',
  'Eric Lee Jr.',
  'Asmar Bilal',
  'Charlie Heck',
  'Kylan Johnson',
  'Casey Toohill',
  'Shaun BradLey',
  'Derrek Tuszka',
  "Ty'Son Williams",
  'Tae Crowder',
  'Logan Wilson',
  'Khaleke Hudson',
  'Xavier Jones',
  'Chad Smith',
  'Josiah Deguara',
  'Nevelle Clarke',
  'John Reid',
  'David Dowell',
  'Dominick Wood-Anderson',
  'Dominique Ross',
  'Marquez Callaway',
  'George Lea',
  'Travion Banks',
  'Jeff Thomas',
  'Marcel Spears Jr.',
  'Robert Landers',
  'Bryce Perkins',
];

export default draftboard;
